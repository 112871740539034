<template>
  <el-dialog
    title="血缘关系"
    :visible.sync="visible"
    width="60%"
    height="500px"
  >
    <div id="mountNode" style="height: 60vh; overflow: auto"></div>
  </el-dialog>
</template>

<script>
import G6 from "@antv/g6";
export default {
  name: "bloodline",
  data() {
    return {
      visible: false,
      // 图的数据
      graph: undefined,
    };
  },
  methods: {
    init(data) {
      this.visible = true;
      this.$nextTick(() => {
        this.initG6();
        // this.handlerData(data)
        this.graph.data(this.handlerData(data));
        this.graph.render();
      });
      // this.initG6();
      // this.graph.data(this.handlerData(data));
      // this.graph.render();
    },
    initG6() {
      if (this.graph) {
        this.graph.destroy();
      }
      this.graph = new G6.Graph({
        container: "mountNode",
        fitView: true,
        layout: {
          type: "dagre",
          rankdir: "LR", // 可选，默认为图的中心
          // align: "DL", // 可选
          // nodesep: 20, // 可选
          // ranksep: 50, // 可选
          controlPoints: true, // 可选
          nodesepFunc: () => 20,
        },
        width: document.getElementById("mountNode").offsetWidth,
        height: document.getElementById("mountNode").offsetWidth / 2,
        defaultEdge: {
          type: "quadratic",
          style: {
            stroke: "#BEBEBE",
            endArrow: {
              path: G6.Arrow.vee(10, 10, 0), // 内置箭头，参数为箭头宽度、长度、偏移量 d（默认为 0）
            },
            lineWidth: 1,
          },
        },
        defaultNode: {
          type: "circle",
          size: 20,
          style: { lineWidth: 1, stroke: "#545C64", fill: "#FFFFFF" },
          labelCfg: {
            position: "bottom",
            style: {
              fill: "#000",
              fontSize: 10,
              fontFamily: "SimSun"
            },
          },
        },
        // plugins: [tooltip],
        // modes: {
        //   default: [
        //     {
        //       type: "tooltip", // 提示框
        //       size: 40,
        //       formatText(model) {
        //         // 提示框文本内容
        //         const text =
        //           "数据源: " + model.label + "<br/> topic: " + model.id;
        //         return text;
        //       },
        //     },
        //   ],
        // },
      });
    },
    handlerData(data) {
      console.log(data);
      let graphData = {
        nodes: [],
        edges: [],
      };
      graphData.nodes = Object.values(data.guidEntityMap).map(item => {
        let obj = {};
        obj.id = item.guid;
        // obj.label = this.newline(item.attributes.name);
        obj.label = item.attributes.name.split("@")[0];
        return obj;
      });
      graphData.edges = data.relations.map(item => {
        let obj = {};
        obj.source = item.fromEntityId;
        obj.target = item.toEntityId;
        return obj;
      })
      return graphData;
    },
    newline(str, step = 20) {
      let newStr = "";
      let count = 0;
      for (let index = 0; index < str.length; index++) {
        newStr += str[index];
        count++;
        if (count == step) {
          newStr += "\n";
          count = 0;
        }
      }
      return newStr;
    }
  },
};
</script>

